import { useCallback, useEffect, useMemo } from 'react';

import { CommonModalsEnum, SessionStorageKeysEnum } from '../enums';

export const useModalFragment = (modalName: CommonModalsEnum, fragmentId?: string) => {
    const modalFragment = useMemo(() => `${modalName}${fragmentId ?? ''}`, [modalName, fragmentId]);

    const addFragment = useCallback(() => {
        setTimeout(() => {
            const currentHash = window.location.hash.slice(1);
            const modals = currentHash ? currentHash.split(',') : [];

            if (!modals.includes(modalFragment)) {
                modals.push(modalFragment);
            }

            window.history.pushState(
                null,
                '',
                `${window.location.pathname}${window.location.search}#${modals.join(',')}`,
            );

            // save to session to controll if modal was opened programmatically
            sessionStorage.setItem(modalName, 'true');
        }, 0);
    }, [modalFragment, modalName]);

    const removeFragment = useCallback(() => {
        setTimeout(() => {
            const currentHash = window.location.hash.slice(1);
            const modals = currentHash ? currentHash.split(',') : [];
            const remainingModals = modals.filter(item => item !== modalFragment);
            const newHash = remainingModals.length > 0 ? `#${remainingModals.join(',')}` : '';

            const safeReplace = () =>
                window.history.replaceState(
                    null,
                    '',
                    `${window.location.pathname}${window.location.search}${newHash}`,
                );

            // check if modal was opened programmatically
            if (sessionStorage.getItem(modalName)) {
                sessionStorage.removeItem(modalName);
                if (sessionStorage.getItem(SessionStorageKeysEnum.CriteriaChanged)) {
                    // fallback in case if query params changed
                    safeReplace();
                    remainingModals.length === 0 &&
                        sessionStorage.removeItem(SessionStorageKeysEnum.CriteriaChanged);
                } else {
                    window.history.back();
                }
            } else {
                // fallback in case if modal was opened after direct navigation from URL
                safeReplace();
            }
        }, 0);
    }, [modalFragment, modalName]);

    return { addFragment, removeFragment };
};

type ModalActions = {
    addFragment: () => void;
    removeFragment: () => void;
};

type ModalValue = (typeof CommonModalsEnum)[keyof typeof CommonModalsEnum];

export const useFragmentActions = (modal: ModalValue, fragmentId?: string): ModalActions => {
    const calendar = useModalFragment(CommonModalsEnum.Calendar, fragmentId);
    const guestPicker = useModalFragment(CommonModalsEnum.GuestPicker, fragmentId);
    const search = useModalFragment(CommonModalsEnum.SearchEngine);
    const chatForm = useModalFragment(CommonModalsEnum.ChatForm);
    const inquiryForm = useModalFragment(CommonModalsEnum.InquiryForm);

    const allActions = useMemo(
        () => ({
            [CommonModalsEnum.Calendar]: {
                addFragment: calendar.addFragment,
                removeFragment: calendar.removeFragment,
            },
            [CommonModalsEnum.GuestPicker]: {
                addFragment: guestPicker.addFragment,
                removeFragment: guestPicker.removeFragment,
            },
            [CommonModalsEnum.SearchEngine]: {
                addFragment: search.addFragment,
                removeFragment: search.removeFragment,
            },
            [CommonModalsEnum.ChatForm]: {
                addFragment: chatForm.addFragment,
                removeFragment: chatForm.removeFragment,
            },
            [CommonModalsEnum.InquiryForm]: {
                addFragment: inquiryForm.addFragment,
                removeFragment: inquiryForm.removeFragment,
            },
        }),
        [calendar, guestPicker, search, inquiryForm, chatForm],
    );

    return allActions[modal as keyof typeof allActions];
};

interface UseModalSyncProps {
    modalName: CommonModalsEnum;
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
    fragmentId?: string;
}

export const useModalSync = ({
    modalName,
    onOpen,
    onClose,
    isOpen,
    fragmentId,
}: UseModalSyncProps) => {
    useEffect(() => {
        const handleHashChange = () => {
            const currentHash = window.location.hash.slice(1);
            const modals = currentHash ? currentHash.split(',') : [];

            if (!modals.includes(modalName)) {
                sessionStorage.getItem(modalName) && sessionStorage.removeItem(modalName);
                isOpen && onClose();
            } else {
                onOpen();
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [modalName, fragmentId, onClose, onOpen, isOpen]);

    useEffect(() => {
        if (modalName !== CommonModalsEnum.SearchEngine) return;

        if (window.location.hash.slice(1).includes(modalName)) {
            onOpen();
        }
    }, [modalName, onOpen]);
};
