import { FC, useMemo } from 'react';

import { Button } from '@chakra-ui/button';
import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/modal';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { differenceInDays } from 'date-fns/differenceInDays';
import isNil from 'lodash/isNil';

import { dateHelpers, FeatureToggleEnum } from '@nocowanie/core';

import { RangePickerDrawerProps } from './range-picker-drawer.props';

import { getFeatureToggleValue, wordPluralisation } from '../../../helpers';

export const RangePickerDrawer = ({
    translationData,
    onClose,
    onSubmit,
    isOpen = true,
    startDate,
    endDate,
    displayedDateFormat = 'dd MMM.',
    contentRef,
    inputRef,
    footerContent,
    setIsLoading,
    isLoading,
    children,
}: RangePickerDrawerProps) => {
    const FT_USE_NEW_CALENDAR = getFeatureToggleValue<boolean>(FeatureToggleEnum.USE_NEW_CALENDAR);

    const DisplayedValue: FC = () => {
        const nightsLabel = useMemo(() => {
            if (!startDate || !endDate) {
                return;
            }

            const nightsCount = differenceInDays(endDate, startDate);

            return `${nightsCount} ${wordPluralisation(
                Number(nightsCount),
                translationData.nights.singular,
                translationData.nights.plural,
                translationData.nights.genitivePlural,
            )}`;
        }, [startDate, endDate]);

        const getLabelValue = (date: Date | null | undefined, placeholderExtension: string) =>
            date ? (
                <strong>{dateHelpers.format(date, displayedDateFormat)}</strong>
            ) : (
                <>
                    {translationData.datePlaceholder} <strong>{placeholderExtension}</strong>
                </>
            );

        const dateFrom = getLabelValue(startDate, translationData.dateFromPlaceholder);

        if (isNil(startDate) && isNil(endDate)) return dateFrom;

        const dateTo = getLabelValue(endDate, translationData.dateToPlaceholder);

        return (
            <>
                {dateFrom} - {dateTo} {nightsLabel ? `(${nightsLabel})` : null}
            </>
        );
    };

    const renderWeekDayNames = useMemo(() => {
        const [first, ...rest] = [...translationData.weekDayNames];
        const weekDayNames: Array<string> = [...rest, first];

        return weekDayNames?.map(day => (
            <Text as="span" key={day} className="react-datepicker__day-name">
                {day}
            </Text>
        ));
    }, [translationData]);

    const themeStyles = useMultiStyleConfig('RangePickerDrawer', {});
    const buttonColorScheme = themeStyles.button?.colorScheme as string;

    const handleSubmit = () => {
        if (!startDate || !endDate) {
            return;
        }

        setIsLoading?.(true);
        'requestIdleCallback' in window ? requestIdleCallback(() => onSubmit?.()) : onSubmit?.();
    };

    return (
        <Drawer
            isOpen={isOpen}
            placement="bottom"
            onClose={onClose}
            finalFocusRef={inputRef}
            variant={'overlap'}
            portalProps={{
                appendToParentPortal: false,
            }}
        >
            <DrawerOverlay />
            <DrawerContent
                mb={0}
                top={'auto'}
                bottom={0}
                pt={8}
                className={'wc-range-picker-drawer'}
            >
                <DrawerCloseButton />
                {renderWeekDayNames ? (
                    <DrawerHeader fontSize="md" borderBottomWidth={1} pb={2} mb={2}>
                        <Divider width={'100vw'} ml={-6} borderColor={'gray.200'} opacity={1} />
                        <Box className="react-datepicker--drawer" p={'0 0.5rem'}>
                            <Box className="react-datepicker__day-names">{renderWeekDayNames}</Box>
                        </Box>
                    </DrawerHeader>
                ) : null}
                <DrawerBody
                    py={0}
                    display={'flex'}
                    flexDirection={'column'}
                    ref={contentRef}
                    overflow={FT_USE_NEW_CALENDAR ? 'hidden' : 'auto'}
                >
                    {children}
                </DrawerBody>
                <DrawerFooter flexDirection={'column'} gap={4} borderTopWidth={1} mt={3}>
                    <Box sx={themeStyles.footerContent} w={'100%'}>
                        {footerContent ?? <DisplayedValue />}
                    </Box>
                    <Flex width={'100%'} gap={3}>
                        <Button
                            colorScheme={buttonColorScheme}
                            flexGrow={1}
                            variant={'solid'}
                            onClick={handleSubmit}
                            isLoading={isLoading && !!(startDate && endDate)}
                            isDisabled={!!(startDate && !endDate)}
                        >
                            {translationData.submitAction}
                        </Button>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};
