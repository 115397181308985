import React, {
    ReactElement,
    useCallback,
    useEffect,
    useId,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Button } from '@chakra-ui/button';
import { useDisclosure, useOutsideClick } from '@chakra-ui/hooks';
import { Box, Flex, HStack, Text } from '@chakra-ui/layout';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/modal';
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/popover';
import { Skeleton } from '@chakra-ui/skeleton';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { cloneDeep, isEqual, isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

import { IconsLinear } from '@nocowanie/icons';

import { Overlay } from './../../atoms/overlay';
import { defaultGuestsPickerConfig, GuestsPickerConfig } from './guests-picker.config';
import { GuestsPickerProps } from './guests-picker.props';
import { GuestsPickerItem, GuestsPickerItemProps, manageItemKey } from './guests-picker-item';

import { CommonModalsEnum, SessionStorageKeysEnum } from '../../../enums';
import { wordPluralisation } from '../../../helpers';
import { useFragmentActions, useModalSync } from '../../../hooks';
import { defaultGuestsPickerTranslation, GuestsPickerInputDataModel } from '../../../models';

const GuestsPicker = ({
    inputData,
    onInputDataChanged,
    config,
    noArrow = false,
    hideRooms = true,
    isMobileBrowser = true,
    triggerProps = {},
    saveToSession,
    children,
}: GuestsPickerProps): JSX.Element => {
    const {
        roomsConfig,
        adultsConfig,
        totalGuestsCountConfig,
        childrenConfig,
        childAgeItemConfig,
        translationData,
    }: Required<GuestsPickerConfig> = { ...defaultGuestsPickerConfig, ...config };
    const { isOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const themeStyles = useMultiStyleConfig('GuestsPicker', {});
    const buttonColorScheme = themeStyles.button?.colorScheme as string;
    const iconColor = themeStyles.iconColor as string;

    const popoverContentRef = useRef<HTMLDivElement>(null);
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572
    const mobileSubmitBtnRef = useRef(null);

    const locationId = useId();

    const { addFragment, removeFragment } = useFragmentActions(
        CommonModalsEnum.GuestPicker,
        locationId,
    );

    const getInputData = (inputData: GuestsPickerProps['inputData']) => {
        const data = inputData
            ? typeof inputData === 'string'
                ? JSON.parse(inputData)
                : inputData
            : {
                  roomsCount: roomsConfig.default,
                  adultsCount: adultsConfig.default,
                  childrenCount: childrenConfig.default,
              };

        if (hideRooms && 'roomsCount' in data) {
            delete data.roomsCount;
        }

        return data;
    };

    const initialState = getInputData(inputData);

    const [componentState, setComponentState] = useState<GuestsPickerInputDataModel>({
        ...initialState,
    });
    const [previousComponentState, setPreviousComponentState] =
        useState<GuestsPickerInputDataModel>({ ...initialState });
    const [isPickerPending, setIsPickerPending] = useState<boolean>(false);

    useEffect(() => {
        if (isEqual(initialState, previousComponentState)) {
            return;
        }

        setComponentState({ ...initialState });
        setPreviousComponentState({ ...initialState });

        onInputDataChanged && onInputDataChanged(initialState);
    }, [inputData]);

    useEffect(() => {
        if (isOpen || isPopoverOpen) {
            return;
        }

        if (isEqual(previousComponentState, componentState)) {
            return;
        }

        setPreviousComponentState({ ...componentState });

        onInputDataChanged && onInputDataChanged(componentState);
    }, [isOpen, isPopoverOpen]);

    const [getRootElementKeys] = useState<Record<manageItemKey, string>>({
        roomsCount: uuid(),
        adultsCount: uuid(),
        childrenAges: uuid(),
        childrenCount: uuid(),
    });

    const totalCount = useMemo(
        () => (componentState.adultsCount ?? 0) + (componentState.childrenCount ?? 0),
        [componentState],
    );

    const inputLabel = useMemo(() => {
        const { childrenCount = 0, adultsCount = 1 } = componentState;
        const count = childrenCount > 0 ? childrenCount : adultsCount;
        const { singular, plural, genitivePlural } =
            translationData.totalGuestLabel ?? defaultGuestsPickerTranslation.totalGuestLabel;
        const guestlabel = wordPluralisation(count, singular, plural, genitivePlural);
        const childrenLabel = childrenCount ? `+ ${childrenCount} ` : '';

        return `${adultsCount} ${childrenLabel}${guestlabel}`;
    }, [componentState, translationData.totalGuestLabel]);

    const totalGuestsMax = totalGuestsCountConfig.max ?? totalGuestsCountConfig.default ?? 99;
    const guestsMaxValues = {
        adults: adultsConfig.max
            ? Math.min(totalGuestsMax - (componentState.childrenCount ?? 0), adultsConfig.max)
            : undefined,
        children: childrenConfig.max
            ? Math.min(totalGuestsMax - (componentState.adultsCount ?? 0), childrenConfig.max)
            : undefined,
    };

    const incrementGuestsDisabled = totalCount >= totalGuestsMax;

    const isValid = useMemo(() => {
        const { childrenAges } = componentState;

        return (
            totalCount <= (totalGuestsCountConfig.max ?? 0) &&
            (childrenAges || []).every(age => !isNil(age))
        );
    }, [componentState, totalCount]);

    useOutsideClick({
        enabled: isPopoverOpen && isValid,
        ref: popoverContentRef,
        handler: event => {
            onCancelChange();
        },
    });

    const handleOnGuestsInputDataChanged = (key: manageItemKey, value?: number) => {
        if (key === 'childrenAges') {
            return;
        }

        const oldValue = componentState[key];
        const newState = cloneDeep(componentState);
        newState[key] = value;

        if (key === 'childrenCount') {
            switch (true) {
                case (oldValue ?? 0) > (value ?? 0):
                    newState.childrenAges = (newState.childrenAges ?? []).slice(
                        0,
                        -((newState.childrenAges?.length ?? 0) - (value ?? 0)),
                    );
                    break;
                case (oldValue ?? 0) < (value ?? 0):
                    newState.childrenAges = newState.childrenAges ?? [];
                    while (newState.childrenAges.length !== value) {
                        newState.childrenAges.push(childAgeItemConfig.default);
                    }
                    break;
            }
        }

        setComponentState(newState);
    };

    const componentRootElements = useMemo<GuestsPickerItemProps[]>(() => {
        const fields: GuestsPickerItemProps[] = [
            {
                key: getRootElementKeys['adultsCount'],
                itemKey: 'adultsCount',
                name: 'adultsCount',
                config: { ...adultsConfig, max: guestsMaxValues.adults },
                label: translationData.adultsLabel,
                value: componentState.adultsCount,
                isFocusable: true,
                // prevent keyboard opening on mobile which causes dialog 'jumping'
                autoFocus: !isMobileBrowser,
                onValueChanged: handleOnGuestsInputDataChanged,
                disableIncrementValue: incrementGuestsDisabled,
            },
            {
                key: getRootElementKeys['childrenCount'],
                itemKey: 'childrenCount',
                name: 'childrenCount',
                config: { ...childrenConfig, max: guestsMaxValues.children },
                label: translationData.childrenLabel,
                value: componentState.childrenCount,
                isFocusable: true,
                onValueChanged: handleOnGuestsInputDataChanged,
                disableIncrementValue: incrementGuestsDisabled,
            },
        ];

        if (!hideRooms) {
            fields.splice(0, 0, {
                key: getRootElementKeys['roomsCount'],
                itemKey: 'roomsCount',
                config: roomsConfig,
                name: 'roomsCount',
                label: translationData.roomsLabel,
                value: componentState.roomsCount,
                isFocusable: true,
                onValueChanged: handleOnGuestsInputDataChanged,
            });
        }

        return fields;
    }, [componentState]);

    const handleOnChildAgeChange = (index: number, newValue?: number) => {
        const newState = cloneDeep(componentState);

        if (!newState.childrenAges) {
            newState.childrenAges = [];
        }

        newState.childrenAges[index] = newValue;

        setComponentState(newState);
    };

    const renderGuestsPickerItem = (key: string, props: GuestsPickerItemProps): JSX.Element => (
        <GuestsPickerItem key={key} {...props} />
    );
    const renderChildrenSection = (childrenCount?: number, isMobileBrowser = true) => {
        if (!childrenCount) {
            return null;
        }

        return (
            <Box my={3}>
                <Text fontWeight={'bold'} mb={2}>
                    {translationData.childrenAgesLabel}
                </Text>
                {componentState.childrenAges?.some(age => isNil(age)) ? (
                    <Text mb={2} fontSize="xs" color="danger.500">
                        {translationData.childrenAgesRequiredMessage}
                    </Text>
                ) : undefined}
                <Flex
                    flexDirection={'column'}
                    gap={2}
                    overflowY={isMobileBrowser ? undefined : 'auto'}
                    maxH={isMobileBrowser ? undefined : '10rem'}
                    mr={-3}
                    pr={3}
                >
                    {componentState.childrenAges?.map((age, index) =>
                        renderGuestsPickerItem(String(index), {
                            itemKey: 'childrenAges',
                            label: `${index + 1}`,
                            value: age,
                            config: childAgeItemConfig,
                            isFocusable: true,
                            isRequired: true,
                            name: `childrenAges-${index}`,
                            placeholder: translationData.childLabel,
                            onValueChanged: (key, newValue) =>
                                handleOnChildAgeChange(index, newValue),
                        }),
                    )}
                </Flex>
            </Box>
        );
    };

    const onMobileDrawerClick = (): void => {
        if (isOpen) {
            onSubmitChange();
            return;
        }

        setPreviousComponentState({ ...componentState });
        onDrawerOpen();
        setIsPickerPending(false);
        addFragment();
    };

    const closeDrawer = useCallback(() => {
        onDrawerClose();
        removeFragment();
    }, [onDrawerClose, removeFragment]);

    const onSubmitChange = useCallback((): void => {
        setIsPickerPending(false);

        if (!isValid) {
            return;
        }

        isMobileBrowser ? closeDrawer() : setIsPopoverOpen(false);
    }, [closeDrawer, isMobileBrowser, isValid]);

    const onCancelChange = useCallback((): void => {
        setComponentState({ ...previousComponentState });
        isMobileBrowser ? closeDrawer() : setIsPopoverOpen(false);
    }, [closeDrawer, isMobileBrowser, previousComponentState]);

    useModalSync({
        modalName: CommonModalsEnum.GuestPicker,
        onOpen: onDrawerOpen,
        onClose: () => {
            onCancelChange();
            onDrawerClose();
        },
        isOpen,
        fragmentId: locationId,
    });

    useEffect(() => {
        if (!isPopoverOpen) {
            return;
        }
        const onEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onCancelChange();
            }
        };

        document.addEventListener('keydown', onEsc);

        return () => {
            document.removeEventListener('keydown', onEsc);
        };
    }, [isPopoverOpen, onCancelChange]);

    const GuestPickerContent = ({ isMobileBrowser = true }): JSX.Element => (
        <>
            {componentRootElements.map(({ key, ...rootElement }) =>
                renderGuestsPickerItem(String(key), { ...rootElement }),
            )}
            {renderChildrenSection(componentState.childrenCount, isMobileBrowser)}
        </>
    );

    const onChangeSubmit = () => {
        setIsPickerPending(true);
        setTimeout(() => {
            onSubmitChange();
            if (
                !sessionStorage.getItem(SessionStorageKeysEnum.CriteriaChanged) &&
                (isMobileBrowser || saveToSession)
            ) {
                sessionStorage.setItem(SessionStorageKeysEnum.CriteriaChanged, 'true');
            }
        }, 0);
    };

    const renderTriggerElement = (isLoading?: boolean): ReactElement => {
        const functionalTriggerProps = {
            ref: isMobileBrowser ? mobileSubmitBtnRef : undefined,
            onClick: isMobileBrowser
                ? () => {
                      setIsPickerPending(true);
                      requestAnimationFrame(() => {
                          onMobileDrawerClick();
                      });
                  }
                : (ev: React.MouseEvent<HTMLElement>) => {
                      if (isPopoverOpen && !isValid) {
                          ev.preventDefault();
                      }
                  },
        };

        const commonTriggerProps = {
            colorScheme: 'gray',
            alignContent: 'center',
            fontWeight: 'normal',
            ...functionalTriggerProps,
            ...triggerProps,
        };

        const customTriggerElement = children
            ? React.cloneElement(children, {
                  ...functionalTriggerProps,
                  isLoading,
              })
            : undefined;

        if (isMobileBrowser) {
            return (
                customTriggerElement ?? (
                    <Button
                        {...commonTriggerProps}
                        rightIcon={
                            noArrow ? undefined : isPopoverOpen ? (
                                <IconsLinear.ArrowUp2 fontSize={12} color={iconColor} />
                            ) : (
                                <IconsLinear.ArrowDown2 fontSize={12} color={iconColor} />
                            )
                        }
                        leftIcon={
                            <Box sx={themeStyles.icon}>
                                <IconsLinear.Profile2User color={iconColor} />
                            </Box>
                        }
                        minW={'128px'}
                    >
                        <Text as={'span'} mr={'auto'}>
                            {isPickerPending ? (
                                <Skeleton width={'80px'} height={'20px'} />
                            ) : (
                                inputLabel
                            )}
                        </Text>
                    </Button>
                )
            );
        }

        return (
            customTriggerElement ?? (
                <Button
                    minW={'148px'}
                    {...commonTriggerProps}
                    _hover={{ bg: 'inherit' }}
                    rightIcon={
                        noArrow ? undefined : isOpen ? (
                            <IconsLinear.ArrowUp2 fontSize={12} />
                        ) : (
                            <IconsLinear.ArrowDown2 fontSize={12} />
                        )
                    }
                >
                    <IconsLinear.Profile2User mr={2} color={iconColor} /> {inputLabel}
                </Button>
            )
        );
    };

    const renderDesktopVersion = (): JSX.Element => {
        return (
            <Popover
                isLazy={true}
                closeOnBlur={false}
                closeOnEsc={false}
                size="md"
                isOpen={isPopoverOpen}
                onClose={() => setIsPopoverOpen(false)}
                onOpen={() => setIsPopoverOpen(true)}
                placement={'bottom'}
                returnFocusOnClose={true}
            >
                <PopoverTrigger>{renderTriggerElement()}</PopoverTrigger>
                <Overlay isActive={isPopoverOpen} bgColor={'transparent'}>
                    <PopoverContent p={3} textAlign={'left'} ref={popoverContentRef}>
                        <PopoverArrow />
                        {GuestPickerContent({ isMobileBrowser: false })}
                        <HStack mt={3}>
                            <Button
                                colorScheme={buttonColorScheme}
                                flexGrow={1}
                                variant={'solid'}
                                onClick={onChangeSubmit}
                                isLoading={isPickerPending}
                                isDisabled={!isValid}
                            >
                                {translationData.actionSubmit}
                            </Button>
                            <Button
                                colorScheme={'secondary'}
                                flexGrow={1}
                                variant="outline"
                                onClick={onCancelChange}
                            >
                                {translationData.actionCancel}
                            </Button>
                        </HStack>
                    </PopoverContent>
                </Overlay>
            </Popover>
        );
    };

    const renderMobileVersion = (): JSX.Element => {
        return (
            <>
                {renderTriggerElement(isPickerPending)}

                <Drawer
                    isOpen={isOpen}
                    placement="bottom"
                    onClose={onCancelChange}
                    finalFocusRef={mobileSubmitBtnRef}
                    variant={'overlap'}
                    portalProps={{
                        appendToParentPortal: false,
                    }}
                >
                    <DrawerOverlay />
                    <DrawerContent mb={0} top={'auto'} bottom={0}>
                        <DrawerCloseButton />
                        <DrawerHeader fontSize="md" borderBottomWidth={1} mb={3}>
                            {translationData.mobileLabel}
                        </DrawerHeader>
                        <DrawerBody py={0}>
                            {GuestPickerContent({ isMobileBrowser: true })}
                        </DrawerBody>
                        <DrawerFooter gap={6} borderTopWidth={1} mt={3}>
                            <Button
                                colorScheme={buttonColorScheme}
                                flexGrow={1}
                                variant={'solid'}
                                onClick={onChangeSubmit}
                                isLoading={isPickerPending}
                                isDisabled={!isValid}
                            >
                                {translationData.actionSubmit}
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </>
        );
    };

    return isMobileBrowser ? renderMobileVersion() : renderDesktopVersion();
};

export { GuestsPicker };
