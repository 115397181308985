import { StyleFunctionProps } from '@chakra-ui/system';
import { theme as chakraTheme } from '@chakra-ui/theme';
import { extendTheme } from '@chakra-ui/theme-utils';

import { AccommodationItem } from './custom-components/AccommodationItem';
import { AccordionMenuItem } from './custom-components/AccordionMenuItem';
import { Autocomplete } from './custom-components/Autocomplete';
import { AutocompleteItem } from './custom-components/AutocompleteItem';
import { AverageRating } from './custom-components/AverageRating';
import { ExpandableMenuItem } from './custom-components/ExpandableMenuItem';
import { FeaturedCountryItem } from './custom-components/FeaturedCountryItem';
import { FeaturedImageWrapper } from './custom-components/FeaturedImageWrapper';
import { Footer } from './custom-components/Footer';
import { GuestsPickerSelect } from './custom-components/GuestPickerSelect';
import { GuestsPicker } from './custom-components/GuestsPicker';
import { Hero } from './custom-components/Hero';
import { MobileNumberInput } from './custom-components/MobileNumberInput';
import { Navigation } from './custom-components/Navigation';
import { Newsletter } from './custom-components/Newsletter';
import { RangePicker } from './custom-components/RangePicker';
import { RangePickerDrawer } from './custom-components/RangePickerDrawer';
import { SearchEngineDesktop } from './custom-components/SearchEngineDesktop';
import { SearchEngineInstantReservationCheckbox } from './custom-components/SearchEngineInstantReservationCheckbox';
import { SearchEngineMobile } from './custom-components/SearchEngineMobile';
import { SearchEngineMobileDrawer } from './custom-components/SearchEngineMobileDrawer';
import { SearchLocation } from './custom-components/SearchLocation';
import { SeoLinks } from './custom-components/SeoLinks';
import { Support } from './custom-components/Support';

const modalZIndexStyles = {
    dialog: {
        zIndex: 'modal',
    },
    overlay: {
        zIndex: 'modal',
    },
};

const drawerDialogStyle = {
    maxH: 'calc(100% - 4rem)',
    borderTopRadius: '10px',
};

const menuLinkStyle = {
    fontSize: 'sm',
    lineHeight: 1.5,
    py: 2,
    px: 4,
    color: 'secondary.500',
    width: '100%',
    border: 'none',
    borderLeft: '0.25rem solid',
    borderLeftColor: 'transparent',
};

const menuLinkHoverBase = {
    borderLeftColor: 'blue.300',
    textDecoration: 'none',
};

const themeBase = extendTheme({
    fonts: {
        body: 'inherit',
        heading: 'inherit',
    },

    shadows: {
        'eq-md': '0 0 12px rgba(0, 0, 0, 0.06)',
        'lg-top': '0px -10px 25.2px 0px rgba(0, 0, 0, 0.25)',
    },

    sizes: {
        oldContainerWidth: '100rem', // It's deprecated and will be removed after redesign (used in checkout)
        containerWidth: '1320px',
    },
    space: {
        gutterWidth: '1rem',
        gutterWidthDesktop: '1.5rem',
        gutterHeight: '1.5rem',
        sectionGap: '3rem',
        sectionGapDesktop: '5rem',
    },

    semanticTokens: {
        colors: {
            'chakra-body-text': {
                _light: 'gray.900',
            },
            'chakra-border-color': {
                _light: 'gray.200',
            },
        },
        shadows: {
            'search-bar-shadow': '0px 0px 32px 0px rgba(0, 0, 0, 0.15)',
        },
    },

    colors: {
        // Color source:
        // https://www.figma.com/file/OCSbDUypz2Bs87uQU6Szwt/Nocowanie-color-library?node-id=201%3A21&t=eUNyvBqt9h1zuyvG-0
        red: {
            50: '#FDE8E7',
            100: '#FAC7C3',
            200: '#F6A19B',
            300: '#F27B73',
            400: '#F05F55',
            500: '#ED4337',
            600: '#EB3D31',
            700: '#E8342A',
            800: '#E52C23',
            900: '#E01E16',
        },
        yellow: {
            50: '#FFFCE7',
            100: '#FEF8C2',
            200: '#FDF39A',
            300: '#FCEE71',
            400: '#FCEB52',
            500: '#FBE734',
            600: '#FAE42F',
            700: '#FAE027',
            800: '#F9DD21',
            900: '#F8D715',
        },
        orange: {
            25: '#FFF9EB',
            50: '#FFF6E0',
            100: '#FFE9B3',
            200: '#FFDA80',
            300: '#FFCB4D',
            400: '#FFBF26',
            500: '#FFB400',
            600: '#FFAD00',
            700: '#FFA400',
            800: '#FF9C00',
            900: '#FF8C00',
        },
        green: {
            25: '#F4FAF3',
            50: '#EEF7ED',
            100: '#D5EBD2',
            200: '#53AD53',
            300: '#309C30',
            400: '#0D8C0E',
            500: '#007F01',
            600: '#007701',
            700: '#007001',
            800: '#016A02',
            900: '#026203',
        },
        brand: {
            50: '#FFEAEA',
            100: '#FFC9C9',
            200: '#FFA6A6',
            300: '#FF8282',
            400: '#FF6767',
            500: '#FF4C4C',
            600: '#FF4545',
            700: '#FF3C3C',
            800: '#FF3333',
            900: '#FF2424',
        },
        secondary: {
            25: '#EBF1F8',
            50: '#E0EAF4',
            100: '#B3CBE3',
            200: '#80A8D0',
            300: '#4D85BD',
            400: '#266AAE',
            500: '#0050A0',
            600: '#004998',
            700: '#00408E',
            800: '#003784',
            900: '#002773',
        },
        gray: {
            50: '#FAFAFA',
            100: '#F5F5F5',
            200: '#EEEEEE',
            300: '#E0E0E0',
            400: '#BDBDBD',
            500: '#717171',
            600: '#5F5F5F',
            700: '#4E4E4E',
            800: '#3C3C3C',
            900: '#212121',
        },
        lightgreen: {
            50: '#FAFDF9',
            100: '#F7FBF6',
            200: '#F5FAF3',
            300: '#F4FAF3',
            400: '#F2F9F0',
            500: '#F4FAF3',
            600: '#E2E9E1',
            700: '#CBD2CA',
            800: '#B5BCB3',
            900: '#9FA69D',
        },
        success: chakraTheme.colors.green,
        warning: chakraTheme.colors.orange,
        promo: chakraTheme.colors.yellow,
        danger: chakraTheme.colors.red,
        selectiveYellow: '#ffb400',

        logoPrimary: '#FF3939',
        errorBorderColor: chakraTheme.colors.red[500],
    },

    styles: {
        global: {
            '.nocowanie-root, .chakra-portal': {
                boxSizing: 'border-box',
                fontFamily: 'inherit',

                'a:not([class])': {
                    textDecoration: 'underline',
                },
            },
            '.is-drawer-open': {
                overflow: 'hidden',
            },
        },
    },

    components: {
        Alert: {
            baseStyle: {
                container: {
                    borderRadius: 'md',
                    fontSize: 'sm',
                },
                icon: {
                    alignSelf: 'start',
                    mr: 4,
                },
            },
        },
        Avatar: {
            sizes: {
                md: {
                    container: {
                        '--avatar-size': '2.5rem',
                        '--avatar-font-size': 'calc(2.5rem / 2.5)',
                    },
                },
            },
        },
        Modal: {
            baseStyle: {
                ...modalZIndexStyles,
            },
            variants: {
                stickyHeader: {
                    header: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 'docked',
                        borderBottom: '1px solid',
                        borderBottomColor: 'gray.300',
                        bg: 'white',
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    bg: 'white',
                    borderRadius: 'base',
                    borderColor: 'gray.400',
                },
            },
            sizes: {
                lg: {
                    control: {
                        width: {
                            base: '16px',
                            md: '18px',
                        },
                        height: {
                            base: '16px',
                            md: '18px',
                        },
                        mt: {
                            base: '1px',
                            md: '3px',
                        },
                    },
                },
            },
        },
        Drawer: {
            baseStyle: {
                ...modalZIndexStyles,
                dialog: {
                    maxH: 'var(--chakra-vh)',
                    height: 'var(--chakra-vh)',
                },
            },
            variants: {
                withMargin: {
                    dialog: {
                        ...drawerDialogStyle,
                        height: '100vh',
                    },
                },
                autoHeight: {
                    dialog: {
                        ...drawerDialogStyle,
                        h: 'auto',
                    },
                },
                contentHeight: {
                    dialog: {
                        ...drawerDialogStyle,
                        // iOS issue - we need to set it to inline-block otherwise 'max-content' won't apply
                        // https://stackoverflow.com/questions/27456607/css-width-max-content-in-safari-browser
                        display: 'inline-block',
                        height: 'max-content',
                        minH: '50vh',
                        overflow: 'auto',
                    },
                    header: {
                        position: 'sticky',
                        background: 'white',
                        top: 0,
                    },
                    body: {
                        minH: 'calc(50vh - 7rem)',
                    },
                },
                navigation: {
                    dialog: {
                        height: 'calc(100vh - 65px)',
                        maxH: 'calc(100vh - 65px)',
                        mt: '65px',
                    },
                    body: {
                        background: 'blue.50',
                    },
                },
                overlap: {
                    dialog: {
                        ...drawerDialogStyle,
                        height: '100vh',
                    },
                    // override container to overlap accommodation sticky bar
                    dialogContainer: {
                        zIndex: chakraTheme.zIndices.modal + 2,
                    },
                },
            },
        },

        Icon: {
            parts: ['box', 'icon'],
            baseStyle: {
                box: {
                    display: 'inline-flex',
                    p: '1.125em',
                },
            },
            sizes: {
                xxs: {
                    box: {
                        p: '0.5em',
                    },
                    icon: {
                        fontSize: '0.75rem',
                        '--icon-stroke': 1.25,
                    },
                },
                xs: {
                    icon: {
                        fontSize: 'md',
                        '--icon-stroke': 1.25,
                    },
                },
                sm: {
                    icon: {
                        fontSize: 'lg',
                        '--icon-stroke': 1.5,
                    },
                },
                md: {
                    icon: {
                        fontSize: '2xl',
                        '--icon-stroke': 1.75,
                    },
                },
                lg: {
                    icon: {
                        fontSize: '3xl',
                        '--icon-stroke': 1.75,
                    },
                },
                xl: {
                    icon: {
                        fontSize: '4xl',
                        '--icon-stroke': 1.75,
                    },
                },
                '2xl': {
                    icon: {
                        fontSize: '6xl',
                        '--icon-stroke': 3.5,
                    },
                },
                '4xl': {
                    icon: {
                        fontSize: '8xl',
                        '--icon-stroke': 4.5,
                    },
                },
                '8xl': {
                    icon: {
                        fontSize: '9xl',
                        '--icon-stroke': 1.75,
                    },
                },
            },

            variants: {
                default: {
                    box: {
                        borderRadius: 'full',
                    },
                },
                darkblue: {
                    box: {
                        background: 'secondary.500',
                        borderRadius: 'full',
                        // _dark: {},
                    },
                    icon: {
                        color: 'secondary.50',
                    },
                },

                lightblue: {
                    box: {
                        background: 'secondary.50',
                        borderRadius: 'full',
                        // _dark: {},
                    },
                    icon: {
                        color: 'secondary.500',
                    },
                },

                lightgray: {
                    box: {
                        background: 'gray.200',
                        borderRadius: 'full',
                    },
                },
            },

            defaultProps: {
                size: 'md',
            },
        },

        Input: {
            baseStyle: {
                colorScheme: 'gray',
                focusBorderColor: 'secondary.500',
            },

            sizes: {
                md: {
                    field: {
                        h: '12',
                    },
                },
            },
        },

        Select: {
            baseStyle: {
                colorScheme: 'gray',
                focusBorderColor: 'secondary.500',
            },

            sizes: {
                md: {
                    field: {
                        h: '12',
                    },
                },
            },
        },

        FormLabel: {
            baseStyle: {
                fontWeight: 400,
                fontSize: 'sm',
                mb: '1',
            },
        },

        Button: {
            baseStyle: {
                fontWeight: 700,
            },
            defaultProps: {
                colorScheme: 'secondary',
                variant: 'outline',
            },

            sizes: {
                sm: {
                    h: '8',
                },
                md: {
                    h: '12',
                },
            },

            variants: {
                link: {
                    fontWeight: 400,
                },

                'link-invisible': (props: StyleFunctionProps) => {
                    const buttonStyles = chakraTheme?.components?.Button?.variants?.link(props);

                    return {
                        ...buttonStyles,
                        color: 'inherit',
                        fontSize: '1.125em',
                        minWidth: 0,
                    };
                },

                muted: (props: StyleFunctionProps) => {
                    const buttonStyles = chakraTheme?.components?.Button?.variants?.solid(props);
                    const { colorScheme } = props;

                    return {
                        ...buttonStyles,
                        bg: `${colorScheme}.50`,
                        color: `${colorScheme}.500`,
                        _hover: {
                            bg: `${colorScheme}.50`,
                            color: `${colorScheme}.500`,
                        },
                        _focus: {
                            bg: `${colorScheme}.100`,
                            color: `${colorScheme}.700`,
                        },
                    };
                },

                expandableMenuItem: {
                    ...menuLinkStyle,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 4,
                    fontWeight: 'normal',
                    h: 'max-content',
                    borderRadius: 'none',
                    bg: 'white',
                    _hover: {
                        ...menuLinkHoverBase,
                        bg: 'blue.50',
                    },
                    _focus: {
                        bg: 'white',
                    },
                },

                filterItem: (props: StyleFunctionProps) => {
                    const buttonStyles = chakraTheme?.components?.Button?.variants?.outline(props);

                    return {
                        ...buttonStyles,
                        color: 'gray.600',
                        bg: 'white',
                        _hover: {
                            bg: 'gray.50',
                        },
                        _active: {
                            bg: 'gray.50',
                        },
                    };
                },

                filterItemActive: (props: StyleFunctionProps) => {
                    const buttonStyles = chakraTheme?.components?.Button?.variants?.outline(props);

                    return {
                        ...buttonStyles,
                        color: 'white',
                        bg: 'secondary.500',
                        _hover: {
                            bg: 'secondary.500',
                        },
                        _active: {
                            bg: 'secondary.500',
                        },
                    };
                },
            },
        },

        Popover: {
            baseStyle: {
                popper: {
                    zIndex: 'popover',
                },
            },
        },

        Tag: {
            baseStyle: {
                container: {
                    fontWeight: 400,
                },
            },
            sizes: {
                sm: {
                    label: {
                        lineHeight: 1,
                        fontSize: 'xs',
                    },
                },
            },
            variants: {
                'solid-light': (props: StyleFunctionProps) => {
                    const tagStyles = chakraTheme?.components?.Tag?.variants?.solid(props);

                    return {
                        ...tagStyles,
                        container: {
                            ...tagStyles?.container,
                            '--badge-bg': `colors.${props.colorScheme}.50`,
                            '--badge-color': 'colors.gray.900',
                        },
                    };
                },
            },
        },

        Skeleton: {
            baseStyle: {
                borderRadius: 'md',
            },
        },

        Switch: {
            variants: {
                reversed: {
                    thumb: {
                        bg: 'secondary.500',
                    },
                    track: {
                        bg: 'white',
                        border: '1px solid',
                        borderColor: 'gray.400',
                        _checked: {
                            bg: 'white',
                        },
                    },
                },
            },
        },

        Card: {
            defaultProps: {
                size: 'md',
            },
            sizes: {
                md: {
                    container: {
                        borderRadius: '3xl',
                    },
                },
            },
            variants: {
                'no-border': {
                    container: {
                        border: 'none',
                    },
                },
            },
        },

        Radio: {
            parts: ['label'],
            baseStyle: {
                label: {
                    flex: 1,
                },
            },
        },

        Menu: {
            parts: ['button', 'list', 'groupTitle'],
            baseStyle: {
                button: {
                    color: 'secondary.500',
                    borderColor: 'transparent',
                    px: 4,
                    py: 3,
                    _hover: {
                        bg: 'transparent',
                    },
                    _active: {
                        bg: 'transparent',
                    },
                    _focusVisible: {
                        outline: '2px solid black',
                    },
                },
                list: {
                    borderRadius: '0',
                },
                groupTitle: {
                    fontSize: 'md',
                    pl: 1,
                    py: 1,
                    color: 'gray.500',
                },
                item: {
                    color: 'secondary.500',
                },
            },
        },

        Link: {
            variants: {
                menuLinkBase: {
                    ...menuLinkStyle,
                    _hover: {
                        ...menuLinkHoverBase,
                        bg: 'blue.50',
                    },
                    _focus: {
                        bg: 'white',
                    },
                },
                menuLinkInner: {
                    ...menuLinkStyle,
                    bg: {
                        base: 'white',
                        md: 'blue.50',
                    },
                    _hover: {
                        textDecoration: 'none',
                        bg: {
                            base: 'blue.50',
                            md: 'white',
                        },
                        borderLeftColor: {
                            base: 'transparent',
                            md: 'blue.300',
                        },
                    },
                    py: 1.5,
                },
            },
        },

        Spinner: {
            defaultProps: {
                thickness: '4px',
                speed: '0.75s',
                emptyColor: 'gray.200',
                color: 'secondary.500',
                size: 'xl',
            },
        },

        // custom components
        AccordionMenuItem,
        Autocomplete,
        AutocompleteItem,
        AccommodationItem,
        AverageRating,
        ExpandableMenuItem,
        FeaturedCountryItem,
        FeaturedImageWrapper,
        Footer,
        GuestsPicker,
        GuestsPickerSelect,
        Hero,
        MobileNumberInput,
        Navigation,
        Newsletter,
        RangePicker,
        SearchEngineDesktop,
        RangePickerDrawer,
        SearchEngineMobile,
        SearchEngineMobileDrawer,
        SearchLocation,
        SearchEngineInstantReservationCheckbox,
        SeoLinks,
        Support,
    },
});

export { themeBase };
