import { useRouter } from 'next/router';

import { useEffect, useState } from 'react';

import isNil from 'lodash/isNil';

import { LocalStorageKey } from '@nocowanie/core';
import { assignDefaultValuesIfNeeded, SearchFormDataType } from '@nocowanie/common-ui';

import { SearchCriteriaKeys } from '@app/enums';

export const useDefaultSearchFormData = (initialSearchData?: SearchFormDataType) => {
    const { pathname } = useRouter();
    const isHomePage = pathname === '/';
    const [initialSearchFormData, setInitialSearchFormData] = useState({
        ...assignDefaultValuesIfNeeded({
            ...initialSearchData,
        }),
    });

    useEffect(() => {
        if (
            !isHomePage ||
            typeof localStorage === 'undefined' ||
            isNil(localStorage.getItem(LocalStorageKey.SearchCriteria))
        ) {
            return;
        }

        const lsSearchCriteria = localStorage.getItem(LocalStorageKey.SearchCriteria) as string;
        const decodedSearchString = decodeURIComponent(lsSearchCriteria);
        const params = new URLSearchParams(decodedSearchString);

        const checkInStr = params.get(SearchCriteriaKeys.CheckIn);
        const checkOutStr = params.get(SearchCriteriaKeys.CheckOut);
        const adultsStr = params.get(SearchCriteriaKeys.Adults);
        const childrenStr = params.get(SearchCriteriaKeys.Children);
        const childrenAgesStr = params.get(SearchCriteriaKeys.ChildrenAges);
        const onlineReservation = params.get(SearchCriteriaKeys.OnlineReservation);

        const defaultData = assignDefaultValuesIfNeeded({ ...initialSearchData });

        const checkInDate = checkInStr ? new Date(checkInStr) : defaultData.dates.checkInDate;
        const checkOutDate = checkOutStr ? new Date(checkOutStr) : defaultData.dates.checkOutDate;
        const adultsCount = adultsStr ? parseInt(adultsStr, 10) : defaultData.guests.adultsCount;
        const childrenCount = childrenStr
            ? parseInt(childrenStr, 10)
            : defaultData.guests.childrenCount;
        const childrenAges =
            childrenAgesStr && childrenAgesStr.trim().length > 0
                ? childrenAgesStr
                      .split(',')
                      .map(age => parseInt(age, 10))
                      .filter(age => !isNaN(age))
                : [];

        setInitialSearchFormData({
            location: '',
            dates: {
                checkInDate,
                checkOutDate,
            },
            guests: {
                adultsCount,
                childrenCount,
                childrenAges,
            },
            instantReservation: onlineReservation ? !!+onlineReservation : false,
        });
    }, [isHomePage]);

    return initialSearchFormData;
};
